import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
   providedIn: 'root'
})
export class ConfettiService {
   private canvas!: HTMLCanvasElement;
   private context!: CanvasRenderingContext2D;
   private confetti: Confetti[] = [];
   private lastUpdated: number = Date.now();
   private renderer: Renderer2;

   constructor(rendererFactory: RendererFactory2) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.initCanvas();
   }

   // Initialize canvas and attach it to the body
   private initCanvas(): void {
      this.canvas = this.renderer.createElement('canvas');
      this.renderer.setStyle(this.canvas, 'position', 'fixed');
      this.renderer.setStyle(this.canvas, 'top', '0');
      this.renderer.setStyle(this.canvas, 'left', '0');
      this.renderer.setStyle(this.canvas, 'width', '100%');
      this.renderer.setStyle(this.canvas, 'height', '100%');
      this.renderer.setStyle(this.canvas, 'pointer-events', 'none');
      this.renderer.setStyle(this.canvas, 'z-index', '9999');

      document.body.appendChild(this.canvas);
      this.context = this.canvas.getContext('2d')!;

      this.resizeCanvas();
      window.addEventListener('resize', this.resizeCanvas.bind(this));
      requestAnimationFrame(this.loop.bind(this));
   }

   // Resize the canvas when the window resizes
   private resizeCanvas(): void {
      const scale = window.devicePixelRatio || 1;
      this.canvas.width = window.innerWidth * scale;
      this.canvas.height = window.innerHeight * scale;
      this.context.scale(scale, scale);
   }

   // Adds confetti to be animated
   public addConfetti(config: Partial<ConfettiConfig> = {}): void {
      const { confettiCount, confettiRadius, confettiColors, emojis, svgIcon } = {
         ...defaultConfettiConfig,
         ...config
      };

      const baseY = (5 * window.innerHeight) / 7;

      for (let i = 0; i < confettiCount / 2; i++) {
         this.confetti.push(new Confetti({ x: 0, y: baseY }, 'right', confettiRadius, confettiColors, emojis, svgIcon));
         this.confetti.push(
            new Confetti({ x: window.innerWidth, y: baseY }, 'left', confettiRadius, confettiColors, emojis, svgIcon)
         );
      }
   }

   // Clears existing confetti and starts new
   public resetAndStart(config: Partial<ConfettiConfig> = {}): void {
      this.confetti = [];
      this.addConfetti(config);
   }

   // Animation loop to update and draw confetti
   private loop(): void {
      const currentTime = Date.now();
      const deltaTime = currentTime - this.lastUpdated;
      this.lastUpdated = currentTime;

      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.confetti = this.confetti.filter(item => {
         item.updatePosition(deltaTime, currentTime);
         item.draw(this.context);
         return item.isVisible(this.canvas.height);
      });

      requestAnimationFrame(this.loop.bind(this));
   }
}

// Confetti Configuration
const defaultConfettiConfig: ConfettiConfig = {
   confettiCount: 500,
   confettiRadius: 6,
   confettiColors: ['#fcf403', '#62fc03', '#f4fc03', '#03e7fc', '#03fca5', '#a503fc', '#fc03ad', '#fc03c2'],
   emojis: [],
   svgIcon: null
};

// Confetti Config Interface
interface ConfettiConfig {
   confettiCount: number;
   confettiRadius: number;
   confettiColors: string[];
   emojis: string[];
   svgIcon: string | null;
}

// Confetti Class (Handles Individual Confetti)
class Confetti {
   position: { x: number; y: number };
   private initialPosition: { x: number; y: number };
   private speed: { x: number; y: number };
   private finalSpeedX: number;
   private rotationSpeed: number;
   private dragCoefficient: number;
   private radius: { x: number; y: number };
   private initialRadius: number;
   private rotationAngle: number;
   private emojiRotationAngle: number;
   private radiusYDirection: 'up' | 'down' = 'down';
   private absCos: number;
   private absSin: number;
   private createdAt: number;
   private direction: 'left' | 'right';
   private color: string | null;
   private emoji: string | null;
   private svgIcon: HTMLImageElement | null = null;

   constructor(
      initialPosition: { x: number; y: number },
      direction: 'left' | 'right',
      radius: number,
      colors: string[],
      emojis: string[],
      svgIcon: string | null
   ) {
      const angle =
         (direction === 'left' ? Math.random() * (15 - 82) + 82 : Math.random() * (-15 - -82) - 15) * (Math.PI / 180);

      this.speed = { x: Math.random() * (1.7 - 0.9) + 0.9, y: Math.random() * (1.7 - 0.9) + 0.9 };
      this.finalSpeedX = Math.random() * (0.6 - 0.2) + 0.2;
      this.rotationSpeed = Math.random() * (0.07 - 0.03) + 0.03;
      this.dragCoefficient = Math.random() * (0.0009 - 0.0005) + 0.0005;
      this.radius = { x: radius, y: radius };
      this.initialRadius = radius;
      this.rotationAngle = Math.random() * (0.2 - -0.2) - 0.2;
      this.emojiRotationAngle = Math.random() * (2 * Math.PI);
      this.absCos = Math.abs(Math.cos(angle));
      this.absSin = Math.abs(Math.sin(angle));

      this.position = { ...initialPosition };
      this.initialPosition = { ...initialPosition };
      this.color = emojis.length || svgIcon ? null : colors[Math.floor(Math.random() * colors.length)];
      this.emoji = emojis.length ? emojis[Math.floor(Math.random() * emojis.length)] : null;

      if (svgIcon) {
         this.svgIcon = new Image();
         this.svgIcon.src = svgIcon;
      }

      this.createdAt = Date.now();
      this.direction = direction;
   }

   draw(context: CanvasRenderingContext2D): void {
      if (!context) return;

      const { x, y } = this.position;
      const { x: radiusX, y: radiusY } = this.radius;

      if (this.color) {
         context.fillStyle = this.color;
         context.beginPath();
         context.ellipse(x, y, radiusX, radiusY, this.rotationAngle, 0, 2 * Math.PI);
         context.fill();
      }
   }

   updatePosition(deltaTime: number, currentTime: number): void {
      const elapsed = currentTime - this.createdAt;

      if (this.speed.x > this.finalSpeedX) {
         this.speed.x -= this.dragCoefficient * deltaTime;
      }

      this.position.x += this.speed.x * (this.direction === 'left' ? -this.absCos : this.absCos) * deltaTime;
      this.position.y = this.initialPosition.y - this.speed.y * this.absSin * elapsed + (0.00125 * elapsed ** 2) / 2;
   }

   isVisible(canvasHeight: number): boolean {
      return this.position.y < canvasHeight + 100;
   }
}
