import { DestroyRef, inject, Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/Services/main.service';
import { environment } from 'src/environments/environment';
import { ChatService } from './chat.service';
import {
   IsTypingMessagePayload,
   Message,
   SeenMessagePayload,
   SOCKET_ACTIONS,
   SocketMessage,
   StatusType
} from '../models/chat';
import { Client } from '@stomp/stompjs';
import { Notification, NotificationType } from '../../profile/models/notification';
import { PostService } from '../../home/services/post.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileService } from '../../profile/services/profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomModalComponent } from 'src/app/shared/components/custom-modal/custom-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ConfettiService } from 'src/app/utils/confetti.service';

const CHAT_BASE_URL = environment.CHAT_WEBSOCKET_URL;
const NOTIF_BASE_URL = environment.NOTIF_WEBSOCKET_URL;
@Injectable({
   providedIn: 'root'
})
export class WebsocketService {
   chatStompClient: any;
   notifStompClient: any;

   private socketConnectionsValue = { chat: false, notification: false };
   private socketSource: BehaviorSubject<{ chat: boolean; notification: boolean }> = new BehaviorSubject<{
      chat: boolean;
      notification: boolean;
   }>(this.socketConnectionsValue);
   socketConnections = this.socketSource.asObservable();

   private mainService = inject(MainService);
   private chatService = inject(ChatService);
   private postService = inject(PostService);
   private profileService = inject(ProfileService);
   private ngbModal = inject(NgbModal);
   private destroyRef = inject(DestroyRef);
   private router = inject(Router);
   private confettiService = inject(ConfettiService);

   constructor() {
      this.startSocket();
   }

   startSocket() {
      this.mainService.userData.subscribe({
         next: userData => {
            if (userData) {
               const token = localStorage.getItem('token');
               this.chatSocketConnect(userData, token as string);
               this.notifSocketConnect(userData, token as string);
            }
         }
      });
   }

   notifSocketConnect(userData: any, token: string) {
      if (this.notifStompClient?.active) {
         return;
      }

      const connectHeaders = {
         Authorization: token as string,
         userId: userData.id
      };

      this.notifStompClient = new Client({
         brokerURL: NOTIF_BASE_URL + '?Authorization=' + token,
         connectHeaders: connectHeaders,
         onConnect: () => {
            console.log('notif socket connected');
            this.socketConnectionsValue.notification = true;
            this.socketSource.next(this.socketConnectionsValue);
            this.notifStompClient.subscribe(
               `/user/${userData.id}/queue/messages`,
               (message: any) => {
                  this.onNotificationReceived(message);
               },
               { userId: userData.id }
            );
         },
         debug: msg => {},
         onStompError: frame => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
         },
         onWebSocketClose: event => {
            console.error('WebSocket closed unexpectedly: ', event);
         },
         onWebSocketError: error => {
            console.error('WebSocket error: ', error);
         }
      });
      this.notifStompClient.activate();
   }

   async onNotificationReceived(payload: any) {
      const socketMessage: Notification = JSON.parse(payload.body);
      this.profileService.increaseNotificationCount(1);
      switch (socketMessage.type) {
         case NotificationType.GAIN_CB_PACK:
            this.showPackageWinModal(socketMessage);
            break;
         case NotificationType.GAIN_CB:
            this.postService.setCBGained(socketMessage.content);
            break;
         case NotificationType.PURCHASE_PRODUCT:
            this.postService.productOrderedNotification();
            break;
         default:
            break;
      }
   }

   showPackageWinModal(message: Notification) {
      this.confettiService.addConfetti();

      const modalRef = this.ngbModal.open(CustomModalComponent, {
         centered: true
      });
      modalRef.componentInstance.closeButton.set(true);
      modalRef.componentInstance.isHtml.set(true);
      modalRef.componentInstance.modalMessage.set(`
      <h2 class='text-center fw-600 text-dark mb-5 font-xl'>Congratulations!</h2>
      <p class='fw-600 text-center text-dark mb-4 font-sm'>You've Won <span class='badge badge-primary font-sm'>${message.content} CB</span> Package</p>
      `);
      modalRef.componentInstance.closeButton.set(true);
      modalRef.componentInstance.cancelButton.set(true);
      modalRef.componentInstance.cancelButtonText.set('Close');
      modalRef.componentInstance.confirmButton.set(true);
      modalRef.componentInstance.confirmButtonText.set('See post');

      (modalRef.componentInstance.confirmed as Observable<null>).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
         next: () => {
            modalRef.close();
            this.router.navigate(['/timeline', 'post', message.postId]);
         }
      });
   }

   chatSocketConnect(userData: any, token: string) {
      if (this.chatStompClient?.active) {
         return;
      }

      const connectHeaders = {
         Authorization: token as string,
         userId: userData.id
      };

      this.chatStompClient = new Client({
         brokerURL: CHAT_BASE_URL + '?Authorization=' + token,
         connectHeaders: connectHeaders,
         onConnect: () => {
            console.log('chat socket connected');
            this.socketConnectionsValue.chat = true;
            this.socketSource.next(this.socketConnectionsValue);
            this.chatStompClient.subscribe(
               `/user/${userData.id}/queue/messages`,
               (message: any) => {
                  this.onMessageReceived(message);
               },
               { userId: userData.id }
            );
         },
         debug: msg => {},
         onStompError: frame => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
         },
         onWebSocketClose: event => {
            this.chatStompClient.activate();
            console.error('WebSocket closed unexpectedly: ', event);
         },
         onWebSocketError: error => {
            console.error('WebSocket error: ', error);
         }
      });
      this.chatStompClient.activate();
   }

   async onMessageReceived(payload: any) {
      const socketMessage: SocketMessage = JSON.parse(payload.body);
      switch (socketMessage.type) {
         case SOCKET_ACTIONS.MESSAGE:
            this.chatService.addUnreadMessageCount();
            this.chatService.addNewMessageToChat(socketMessage, true);
            break;
         case SOCKET_ACTIONS.UNSEEN_MESSAGE:
            this.chatService.setUnreadMessageCount(socketMessage.unseenMessages!);
            break;
         case SOCKET_ACTIONS.MESSAGE_SEEN:
            this.chatService.setSeenSentMessage(socketMessage);
            break;
         case SOCKET_ACTIONS.USER_STATUS:
            if (socketMessage.statusType === StatusType.COLLECTION) {
               this.setAllUsersStatus(socketMessage.userIds);
               return;
            }
            if (socketMessage.statusType === StatusType.SINGLE) {
               this.setUserStatus(socketMessage.userIds[0], socketMessage.online);
               return;
            }
            break;
         case SOCKET_ACTIONS.TYPING_MESSAGE:
            this.chatService.setIsTypingMessage(socketMessage);
            break;
      }
   }

   setAllUsersStatus(userList: number[]) {
      this.chatService.setOnlineUsers(userList);
   }

   setUserStatus(userId: number, online: boolean) {
      this.chatService.setUserStatus(userId, online);
   }

   stopSocket() {
      if (this.chatStompClient) {
         this.chatStompClient.deactivate();
      }

      if (this.notifStompClient) {
         this.notifStompClient.deactivate();
      }
   }

   sendMessage(message: Message) {
      if (message && this.chatStompClient) {
         const chatMessage = {
            senderId: message.senderId,
            chatRoomId: message.chatRoomId,
            text: message.text,
            messageType: message.messageType
         };
         this.chatStompClient.publish('/app/chat', {}, JSON.stringify(chatMessage));
         this.chatStompClient.publish({ destination: '/app/chat', body: JSON.stringify(chatMessage) });
         this.chatService.addNewMessageToChat(message);
      }
   }

   sendIsTyping(isTypingMessage: IsTypingMessagePayload) {
      if (isTypingMessage && this.chatStompClient) {
         this.chatStompClient.publish('/app/typing', {}, JSON.stringify(isTypingMessage));
         this.chatStompClient.publish({ destination: '/app/typing', body: JSON.stringify(isTypingMessage) });
      }
   }

   seenMessage(seenMessage: SeenMessagePayload) {
      if (seenMessage && this.chatStompClient) {
         this.chatStompClient.publish('/app/seen-message', {}, JSON.stringify(seenMessage));
         this.chatStompClient.publish({ destination: '/app/seen-message', body: JSON.stringify(seenMessage) });
      }
   }
}
