import { User } from 'src/app/utils/user.model';

export enum SOCKET_ACTIONS {
   USER_STATUS = 'USER_STATUS',
   MESSAGE = 'MESSAGE',
   UNSEEN_MESSAGE = 'UNSEEN_MESSAGE',
   MESSAGE_SEEN = 'MESSAGE_SEEN',
   TYPING_MESSAGE = 'TYPING_MESSAGE'
}

export enum StatusType {
   COLLECTION = 'COLLECTION',
   SINGLE = 'SINGLE'
}

export enum MESSAGE_TYPE {
   TEXT = 'TEXT',
   PHOTO = 'PHOTO',
   VIDEO = 'VIDEO'
}

export interface Chat {
   roomId: string;
   userId: number;
   createdDate: string | Date;
   hasNewMessage?: boolean;
   userInfo?: User;
   modifiedDate?: string | Date;
}

export interface MessagePagination {
   content: Message[];
   page: {
      size: number;
      number: number;
      totalElements: number;
      totalPages: number;
   };
}

export interface SocketMessage extends Message, UserStatus {
   type: SOCKET_ACTIONS;
   unseenMessages?: number;
   messageId: string;
   chatRoomId: string;
   roomId?: string;
   seenTime: Date | string;
   typing?: boolean;
}
export interface Message {
   id?: string;
   chatRoomId: string;
   senderId: number;
   receiverId?: number;
   text: string;
   messageType: MESSAGE_TYPE;
   sendTime: string;
   editTime: string;
   seen?: boolean;
   seenTime?: Date | string;
   uploading?: boolean;
   size?: number;
   loaded?: number;
}

export interface SeenMessagePayload {
   userId: number;
   messageId: string;
}

export interface IsTypingMessagePayload {
   senderId: number;
   roomId: string;
   typing: boolean;
}

export interface UserStatus {
   statusType: StatusType;
   online: boolean;
   userIds: number[];
}

export interface ChatObservable {
   userId: number;
   isOnline: boolean;
   username: string;
   name: string;
   photo: string;
   roomId: string;
   messages: Message[];
   created_at: string;
   role: any[];
   hasNewMessage?: boolean;
   typing?: boolean;
   modifiedDate?: string | Date;
}
